import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InvestigacionImage from '../../assets/img/investigacion.png';
import PosterImage from '../../assets/img/poster.png';
import WebImage from '../../assets/img/web.png';
import MaterialDocenteImage from '../../assets/img/mat_doc.png';
import PersonIcon from '@mui/icons-material/Person';
import formatMaterialDocenteAuthors from '../../utils/formatMaterialDocenteAuthors';
import { useNavigate } from 'react-router-dom';


const HomeFeaturedMain = ({ keycloak, item }) => {

    const navigate = useNavigate();

    const getHomeImage = () => {
        if (item) {
            if (item.level == null) {
                if (item.subcategory === "article" || item.subcategory === "congress_minute" || item.subcategory === "technical_report") {
                    return InvestigacionImage;
                } else if (item.subcategory === "book" || item.subcategory === "book_chapter") {
                    return InvestigacionImage;
                } else if (item.subcategory === "poster") {
                    return PosterImage;
                } else if (item.subcategory === "website") {
                    return WebImage;
                } else {
                    return null;
                }
            } else {
                return MaterialDocenteImage;
            }
        }
    }

    return (
        <>
            {item == null ?
                <Typography textAlign={"center"} variant='subtitle'>¡Próximamente!</Typography>
                :
                <>
                    {item.level == null ?
                        <Grid justifyContent={"center"} alignItems={"center"} mb={"20px"}>
                            <img src={getHomeImage()} alt="Investigación" style={{ width: "100%" }} />
                            <Typography
                                width={"auto"}
                                sx={{
                                    "textAlign": "justify",
                                    "color": "#40b4ba",
                                    "fontSize": {"xs": "0.9em", "sm": "1em"},
                                    "borderLeft": "5px solid #646464",
                                    "padding": "0 10px",
                                    "display": "flex",
                                    "alignItems": "center",
                                    "display": '-webkit-box',
                                    "overflow": 'hidden',
                                    "WebkitBoxOrient": 'vertical',
                                    "wordWrap": 'break-word',
                                    "WebkitLineClamp": 2,
                                }}
                                variant='title'
                            >
                                {item.detail.title}
                            </Typography>
                            <Box sx={{ "display": "flex", "alignItems": "center" }}>
                                <PersonIcon sx={{ color: "#646464" }} />
                                <Typography
                                    sx={{
                                        "textAlign": "justify",
                                        "fontSize": {"xs": "0.8em", "sm": "0.85em"},
                                        "paddingLeft": "10px",
                                        "display": "flex",
                                        "alignItems": "center",
                                        "marginBottom": "0px"
                                    }}
                                    variant='subtitle'
                                >
                                    {item.detail.author} ({item.detail.year})
                                </Typography>
                            </Box>
                            <Typography
                                sx={{
                                    "textAlign": "justify",
                                    "fontSize": {"xs": "0.8em", "sm": "0.85em"},
                                    "marginTop": "10px",
                                    "display": '-webkit-box',
                                    "overflow": 'hidden',
                                    "WebkitBoxOrient": 'vertical',
                                    "wordWrap": 'break-word',
                                    "WebkitLineClamp": 5,
                                }}
                                variant='p'
                            >
                                {item.detail.abstract ? item.detail.abstract : item.detail.description}
                            </Typography>
                            <Box textAlign={"center"}>
                                <Button onClick={() => {keycloak.didInitialize && navigate("/recurso/" + item.id)}} variant="orange" style={{ margin: "30px 0 10px 0", fontSize: "0.7em" }}>
                                    Ver más +
                                </Button>
                            </Box>
                        </Grid>
                        :
                        <Box justifyContent={"center"} alignItems={"center"} mb={"20px"}>
                            <img src={getHomeImage()} alt="Material docente" style={{ width: "100%" }} />
                            <Box sx={{ "paddingRight": "20px", "paddingTop": "15px" }}>
                                <Typography
                                    sx={{
                                        "textAlign": "justify",
                                        "color": "#40b4ba",
                                        "fontSize": "1em",
                                        "borderLeft": "5px solid #646464",
                                        "paddingLeft": "10px",
                                        "display": "flex",
                                        "alignItems": "center",
                                        "display": '-webkit-box',
                                        "overflow": 'hidden',
                                        "WebkitBoxOrient": 'vertical',
                                        "wordWrap": 'break-word',
                                        "WebkitLineClamp": 2,
                                    }}
                                    variant='title'
                                >
                                    {item.title}
                                </Typography>
                            </Box>
                            <Box sx={{ "paddingRight": "20px" }}>
                                <Box sx={{ "paddingRight": "20px", "display": "flex", "alignItems": "center" }}>
                                    <PersonIcon sx={{ color: "#646464" }} />
                                    <Typography
                                        sx={{
                                            "textAlign": "justify",
                                            "fontSize": "0.85em",
                                            "paddingLeft": "10px",
                                            "display": "flex",
                                            "alignItems": "center",
                                            "marginBottom": "0px"
                                        }}
                                        variant='subtitle'
                                    >
                                        {formatMaterialDocenteAuthors(item.authors)}
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        "textAlign": "justify",
                                        "fontSize": "0.85em",
                                        "marginTop": "10px",
                                        "display": '-webkit-box',
                                        "overflow": 'hidden',
                                        "WebkitBoxOrient": 'vertical',
                                        "wordWrap": 'break-word',
                                        "WebkitLineClamp": 5,
                                    }}
                                    variant='p'
                                >
                                    {item.description}
                                </Typography>
                                <Box textAlign={"center"}>
                                    <Button onClick={() => {keycloak.didInitialize && navigate("/activity/" + item.id)}} variant="orange" style={{ margin: "30px 0 10px 0", fontSize: "0.7em" }}>
                                        Ver más +
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    }
                </>
            }
        </>
    );
}

export default HomeFeaturedMain;