import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NotFound from '../../Errors/NotFound';
import ActivityContent from './ActivityContent';
import api from '../../API';
import { useNavigate } from 'react-router-dom';


const Activity = ({ keycloak }) => {

  const navigate = useNavigate();

  const [activity, setActivity] = useState(null);
  const [loadingActivity, setLoadingActivity] = useState(true);
  const location = useLocation();
  const pathDetails = location.pathname.split('/').filter(Boolean);

  const loadActivity = () => {
    if (pathDetails.length !== 2) {
      return <NotFound keycloak={keycloak}></NotFound>
    }
    axios.get(api.api_url + "/activity/" + pathDetails[1] + "?username=" + (keycloak.authenticated ? keycloak.idTokenParsed.preferred_username : ""))
      .then(response => {
        setLoadingActivity(false);
        setActivity(response.data);
        document.title = response.data.title + " | RedFID Recursos";
      })
      .catch(error => {
        if(error.response.status === 403) {
          setActivity(null);
          setLoadingActivity(false);
        } else if (error.response.status === 404) {
          setActivity(null);
          setLoadingActivity(false);
        } else {
          console.log(error)
        }
      });
  }

  useEffect(() => {
    if (keycloak.didInitialize){
      loadActivity();
    }
  }, [keycloak.authenticated])

  return (

    loadingActivity ?
      <></>
      : (activity && (activity.visibility !== "private" || (keycloak.authenticated && activity.username === keycloak.idTokenParsed.preferred_username))) ? 
      <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: 'center' }}>
        <Header keycloak={keycloak}></Header>
        <ActivityContent keycloak={keycloak} activity={activity}></ActivityContent> 
        <Footer />
      </Grid>
      :
        <NotFound keycloak={keycloak}></NotFound>


  );
}

export default Activity;