import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InvestigacionIcon from '../../assets/img/investigacion-icon.png';
import PosterIcon from '../../assets/img/poster_icon.png';
import WebIcon from '../../assets/img/web_icon.png';
import BookIcon from '../../assets/img/book_icon.png';
import MaterialDocenteIcon from '../../assets/img/mat_doc_icon.png';
import { useNavigate } from 'react-router-dom';

const HomeFeaturedSide = ({ keycloak, featured }) => {

    const navigate = useNavigate();

    return (
        <>
            {featured.length === 0 ?
                <Grid>
                    <Typography textAlign={"center"} variant='subtitle'>¡Próximamente!</Typography>
                </Grid>
                :
                <Grid sx={{ "maxHeight": "550px", "overflow": "auto", "marginBottom": "20px" }}>
                    {featured.map((item, index) => (
                        <Grid key={index} display="flex" mb={"10px"}>
                            <Box
                                component="img"
                                sx={{
                                    backgroundColor: item.level == null ? (item.subcategory === "article" || item.subcategory === "congress_minute" || item.subcategory === "technical_report" ? "#f2565c" : item.subcategory === "poster" ? "#eb957e" : (item.subcategory === "book" || item.subcategory === "book_chapter") ? "#f2565c" : item.subcategory === "website" ? "#bfdf76" : null) : "#6fbce1",
                                    height: 50,
                                    width: 50,
                                    padding: "5px",
                                    marginRight: "8px"
                                }}
                                alt="Other"
                                src={item.level == null ? (item.subcategory === "article" || item.subcategory === "congress_minute" || item.subcategory === "technical_report" ? InvestigacionIcon : item.subcategory === "poster" ? PosterIcon : (item.subcategory === "book" || item.subcategory === "book_chapter") ? BookIcon : item.subcategory === "website" ? WebIcon : null) : MaterialDocenteIcon}
                            />
                            <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}>
                                <Typography variant='p' fontWeight={"bold"} sx={{
                                    "display": '-webkit-box',
                                    "overflow": 'hidden',
                                    "WebkitBoxOrient": 'vertical',
                                    "wordWrap": 'break-word',
                                    "WebkitLineClamp": 2,
                                }}>{item.level == null ? item.detail.title : item.title}</Typography>
                                <Typography onClick={() => { keycloak.didInitialize && navigate(item.level == null ? "/recurso/" + item.id : "/activity/" + item.id) }} textAlign={"right"} fontSize={"0.9em"} variant='a'>Ver más +</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            }
        </>
    );
}

export default HomeFeaturedSide;