import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFound = ({ keycloak }) => {
  const navigate = useNavigate();
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header keycloak={keycloak} />
      <Box
        component="div"
        p={"0 20px"}
        sx={{
          flexGrow: 1,
          m: '30px auto',
          maxWidth: '940px',
          textAlign: 'center',
        }}
      >
        <Typography variant='title'>Página no encontrada</Typography>
        <Typography onClick={() => {keycloak.didInitialize && navigate("/")}} variant='a'>Volver al Home</Typography>
      </Box>
      <Footer />
    </Box>
  );
}

export default NotFound;