export const formatFileSize = (size) => {
    if (size < 1024) return size + ' Bytes';
    let i = Math.floor(Math.log(size) / Math.log(1024));
    let num = (size / Math.pow(1024, i));
    let round = Math.round(num);
    num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
    return `${num} ${['Bytes', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

export const formatTimestamp = (timestamp) => {
    const date = timestamp.split('T')[0];
    return date;
}

export const formatDOI = (doi) => {
    if (!doi.startsWith('http://') && !doi.startsWith('https://') && !doi.startsWith('doi.org') && !doi.startsWith("dx.doi.org")) {
        return doi.replace(doi, 'https://doi.org/' + doi);
    }
    if (doi.startsWith("doi:")) {
        return doi.replace('doi:', 'https://doi.org/');
    }
    if (doi.startsWith("DOI:")) {
        return doi.replace('DOI:', 'https://doi.org/');
    }
    return doi;
}

export const unformatDOI = (doi) => {
    return doi.replace("https://doi.org/", '');
}
