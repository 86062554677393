const formatMaterialDocenteAuthors = (authors) => {
    let authorsString = "";
    authors.forEach((author, index) => {
        if (index === authors.length - 1) {
            authorsString += author["author"] + " (" + author["institution"] + ")";
        } else {
            authorsString += author["author"] + " (" + author["institution"] + ")" + ", ";
        }
    });
    return authorsString;
}

export default formatMaterialDocenteAuthors;