import React, { useState } from 'react';
import axios from 'axios';
import { Accordion, AccordionDetails, AccordionSummary, AccordionActions, Box, IconButton, Link, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { handleDownload } from '../../utils/download';
import { getCategoryChip, getPendingChip } from '../../utils/chips';
import { useNavigate } from 'react-router-dom';
import api from '../../API';
import { formatDOI, unformatDOI } from '../../utils/formatters';


const MaterialAcademicoSummary = ({ keycloak, item, index, isMine }) => {

    const navigate = useNavigate();

    const [deleteModal, setDeleteModal] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const handleDelete = () => {
        setDeleting(true);
        axios.post(api.api_url + "/delete_recurso_academico/" + item.id, {}, { headers: { 'Authorization': 'Bearer ' + keycloak.token } })
            .then(response => {
                if (response.status === 200) {
                    console.log("Recurso eliminado");
                }
                setDeleting(false);
                handleCloseDeleteModal();
                window.location.reload();   
            })
            .catch(error => {
                setDeleting(false);
                console.log(error)
            });
    }

    const handleOpenDeleteModal = () => {
        setDeleteModal(true);
    }

    const handleCloseDeleteModal = () => {
        setDeleteModal(false);
    }


    return (
        <>
            <Accordion key={index} sx={{ "boxShadow": "none", "backgroundColor": index % 2 === 0 ? "#e9eeef" : "#f3f3f3", "marginBottom": "10px" }}>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls={`panel-${index}-content`}
                    id={`panel-${index}-header`}
                >
                    <Grid width={"100%"} mr={"20px"}>
                        {isMine ?
                            <Box display={"flex"} justifyContent={"space-between"}>
                                {getPendingChip(item.status)}
                                {getCategoryChip(item.subcategory)}
                            </Box>
                            :
                            <Box display={"flex"} justifyContent={"left"}>
                                {getCategoryChip(item.subcategory)}
                            </Box>
                        }
                        <Typography fontSize={{ "xs": "0.9em", "sm": "1em" }} ml={"12px"} textAlign={"justify"} fontWeight={"bold"}>{item.detail.title}</Typography>
                        {item.detail.subtitle &&
                            <Typography ml={"12px"} fontWeight={"bold"} variant="small-text">{item.detail.subtitle}</Typography>
                        }
                        <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} ml={"12px"} variant="small-text">{item.detail.author} ({item.detail.year})</Typography>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid ml={"12px"} mr={"20px"}>
                        <Grid container display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
                            {(item.subcategory !== "website" && item.subcategory !== "poster") ?
                                <Grid item xs={12} sm={item.detail.abstract ? 6 : 12} pr={"10px"} pb={"20px"}>
                                    {(item.detail.reference === "doi") &&
                                        <Box display={"flex"} justifyContent={"left"}>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>DOI: </Typography>
                                            <Link href={formatDOI(item.detail.doi)} target="_blank"><Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant='small-a'>{unformatDOI(formatDOI(item.detail.doi))}</Typography></Link>
                                        </Box>
                                    }
                                    {(item.detail.reference === "url") &&
                                        <Box display={"flex"} justifyContent={"left"}>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>URL: </Typography>
                                            <Link href={item.detail.url} target="_blank"><Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant='small-a'>{item.detail.url}</Typography></Link>
                                        </Box>
                                    }
                                    {item.detail.journal &&
                                        <Box display={"flex"} justifyContent={"left"}>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Revista: </Typography>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text">{item.detail.journal}</Typography>
                                        </Box>
                                    }
                                    {item.detail.editor &&
                                        <Box display={"flex"} justifyContent={"left"}>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Editor: </Typography>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text">{item.detail.editor}</Typography>
                                        </Box>
                                    }
                                    {item.detail.volume &&
                                        <Box display={"flex"} justifyContent={"left"}>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Volumen: </Typography>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text">{item.detail.volume}</Typography>
                                        </Box>
                                    }
                                    {item.detail.issue &&

                                        <Box display={"flex"} justifyContent={"left"}>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Número: </Typography>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text">{item.detail.issue}</Typography>
                                        </Box>
                                    }
                                    {item.detail.pages &&
                                        <Box display={"flex"} justifyContent={"left"}>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Páginas: </Typography>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text">{item.detail.pages}</Typography>
                                        </Box>
                                    }
                                </Grid>
                                : item.subcategory === "website" ?
                                    <Grid item xs={12} sm={item.detail.abstract ? 6 : 12}>
                                        <Box display={"flex"} pb={"20px"}>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"40px"}>URL: </Typography>
                                            <Link href={item.detail.url} target="_blank"><Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant='small-a'>{item.detail.url}</Typography></Link>
                                        </Box>
                                        <Box display={"flex"}>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" textAlign={"justify"}>{item.detail.description}</Typography>
                                        </Box>
                                    </Grid>
                                    : <Grid item xs={12} sm={6}>
                                        <Box display={"flex"} justifyContent={"center"}>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" textAlign={"justify"}>{item.detail.abstract}</Typography>
                                        </Box>
                                    </Grid>
                            }
                            {(item.detail.abstract && item.subcategory !== "poster") &&
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" textAlign={"justify"}>{item.detail.abstract}</Typography>
                                </Grid>
                            }
                        </Grid>
                        <Grid mt={"20px"} mr={"20px"}>
                            {item.justification &&
                                <Box display={"flex"} justifyContent={"left"}>
                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Para citar: </Typography>
                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" textAlign={"justify"}>{item.justification}</Typography>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </AccordionDetails>
                <AccordionActions sx={{ "paddingBottom": "20px" }}>
                    <Typography fontSize={{ "xs": "0.7em", "sm": "0.85em" }} variant="p" mr={"auto"} ml="20px">Material compartido por {item.first_name + " " + item.last_name} para RedFID.</Typography>
                    {keycloak.authenticated && (
                        <IconButton onClick={() => handleDownload("academico", item, api, keycloak.idTokenParsed.preferred_username)}>
                            <DownloadIcon />
                        </IconButton>
                    )}
                    {(!keycloak.authenticated && item["visibility"] === "public") && (
                        <IconButton onClick={() => handleDownload("academico", item, api, "")}>
                            <DownloadIcon />
                        </IconButton>
                    )}
                    {(!keycloak.authenticated && item["visibility"] === "semipublic") && (
                        <>
                            <Tooltip title={
                                <div>
                                    <Typography color="white">Por favor inicie sesión</Typography>
                                </div>
                            }>
                                <IconButton color={"red"}>
                                    {item["subcategory"] === "video" ? <PlayCircleIcon /> : <DownloadIcon />}
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                    {item.status === "approved" &&
                        <IconButton onClick={() => { keycloak.didInitialize && navigate("/recurso/" + item.id) }}>
                            <ShareIcon />
                        </IconButton>
                    }
                    {(keycloak.authenticated && isMine) &&
                        <IconButton onClick={() => handleOpenDeleteModal()}>
                            <DeleteForeverIcon />
                        </IconButton>
                    }
                </AccordionActions>
            </Accordion>
            {isMine &&
                <Dialog
                    fullWidth
                    open={deleteModal}
                    onClose={handleCloseDeleteModal}
                >
                    <DialogTitle sx={{ "margin": "20px 20px 0 20px" }}>{"Eliminar " + (item.status === "proposed" ? "solicitud" : "recurso")}</DialogTitle>
                    <DialogContent sx={{ "margin": "20px 20px 0 20px" }}>
                        <DialogContentText sx={{ "fontFamily": "Avenir Regular", "fontSize": "0.9em" }}>
                            {"¿Está segur@ que desea eliminar " + (item.status === "proposed" ? "esta solicitud?" : "este recurso?")}
                        </DialogContentText>
                        <DialogContentText sx={{ "fontFamily": "Avenir Regular", "fontSize": "0.9em", "marginTop": "10px", "textAlign": "center", "fontWeight": "bold", "margin": "20px 0px" }}>
                            {item.detail.title}
                        </DialogContentText>
                        <DialogContentText sx={{ "fontFamily": "Avenir Regular", "fontSize": "0.9em" }}>
                            {"Esta acción no se puede deshacer."}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ "margin": "0px 20px 20px 20px" }}>
                        <Button variant="cancel" onClick={handleCloseDeleteModal}>Cancelar</Button>
                        <Button variant="orange" onClick={handleDelete}>Eliminar</Button>
                    </DialogActions>
                </Dialog>
            }
        </>

    )
}

export default MaterialAcademicoSummary;