export const concatAuthors = (authors) => {
    var authorsString = '';
    for (var author of authors) {
        if (!author.name) {
            if (authorsString.length > 0) {
                authorsString += ', ';
            }
            authorsString += `${author.family}, ${author.given[0]}.`;
        }
    }
    return authorsString;
}

export const removeHtmlTags = (text) => {
    return text.replace(/<[^>]*>/g, '');
}