import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import LanguageIcon from '@mui/icons-material/Language';
import { handleDownload } from '../../utils/download';
import { getBigChip, getCategoryChip } from '../../utils/chips';
import api from '../../API';
import { useNavigate } from 'react-router-dom';
import { formatDOI, unformatDOI } from '../../utils/formatters';

const RecursoContent = ({ keycloak, recurso }) => {

    const navigate = useNavigate();

    return (
        <Grid
            component="div"
            sx={{
            flexGrow: 1,
            maxWidth: '940px',
            width: '100%',
            padding: "30px 20px",
            }}
        >
            <Typography onClick={() => { keycloak.didInitialize && navigate("/") }} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
                <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
                <Typography variant="back">Volver</Typography>
            </Typography>
            <Box textAlign={"right"}>
                {getCategoryChip(recurso["subcategory"])}
                {getBigChip("material_academico")}
            </Box>
            <Typography sx={{"fontSize": {"xs": "1em", "sm": "1.4em"}}} variant='title' textAlign={"justify"}>{recurso["detail"]["title"]}</Typography>
            <Typography sx={{"fontSize": {"xs": "0.85em", "sm": "1.1em"}}} variant='main-subtitle'>{recurso["detail"]["author"] + " (" + recurso["detail"]["year"] + ")"}</Typography>
            {recurso["detail"]["abstract"] != null &&
                <>
                    <Typography fontSize={{"xs": "0.75em", "sm": "0.85em"}} textAlign={"justify"}>{recurso["detail"]["abstract"]}</Typography>
                </>
            }
            {recurso["detail"]["description"] != null &&
                <>
                    <Typography fontSize={{"xs": "0.75em", "sm": "0.85em"}} textAlign={"justify"}>{recurso["detail"]["description"]}</Typography>
                </>
            }
            <Box mt={"20px"}>
                {(recurso["detail"].reference === "doi") &&
                    <Box display={"flex"} justifyContent={"left"}>
                        <Typography variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>DOI: </Typography>
                        <Link href={formatDOI(recurso["detail"].doi)} target="_blank"><Typography variant='small-a'>{unformatDOI(formatDOI(recurso["detail"].doi))}</Typography></Link>
                    </Box>
                }
                {(recurso["detail"].reference === "url") &&
                    <Box display={"flex"} justifyContent={"left"}>
                        <Typography variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>URL: </Typography>
                        <Link href={recurso["detail"].url} target="_blank"><Typography variant='small-a'>{recurso["detail"].url}</Typography></Link>
                    </Box>
                }
                {recurso["detail"].journal &&
                    <Box display={"flex"} justifyContent={"left"}>
                        <Typography variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Revista: </Typography>
                        <Typography variant="small-text">{recurso["detail"].journal}</Typography>
                    </Box>
                }
                {recurso["detail"].editor &&
                    <Box display={"flex"} justifyContent={"left"}>
                        <Typography variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Editor: </Typography>
                        <Typography variant="small-text">{recurso["detail"].editor}</Typography>
                    </Box>
                }
                {recurso["detail"].volume &&
                    <Box display={"flex"} justifyContent={"left"}>
                        <Typography variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Volumen: </Typography>
                        <Typography variant="small-text">{recurso["detail"].volume}</Typography>
                    </Box>
                }
                {recurso["detail"].issue &&

                    <Box display={"flex"} justifyContent={"left"}>
                        <Typography variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Número: </Typography>
                        <Typography variant="small-text">{recurso["detail"].issue}</Typography>
                    </Box>
                }
                {recurso["detail"].pages &&
                    <Box display={"flex"} justifyContent={"left"}>
                        <Typography variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Páginas: </Typography>
                        <Typography variant="small-text">{recurso["detail"].pages}</Typography>
                    </Box>
                }
                {recurso["justification"] &&
                    <Box display={"flex"} justifyContent={"left"}>
                        <Typography variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Para citar: </Typography>
                        <Typography variant="small-text" textAlign={"justify"}>{recurso["justification"]}</Typography>
                    </Box>
                }
            </Box>
            <Box textAlign={"center"} alignSelf="flex-end">
                {keycloak.authenticated && (
                    recurso.detail.reference === "direct" ?
                        <Button sx={{"fontSize": {"xs": "0.7em", "sm": "0.9em"}}} onClick={() => handleDownload("academico", recurso, api, keycloak.idTokenParsed.preferred_username)} variant="orange" style={{ margin: "30px 0 20px 0" }} startIcon={<DownloadIcon />}>
                            Descargar recurso
                        </Button>
                        :
                        <Button sx={{"fontSize": {"xs": "0.7em", "sm": "0.9em"}}} onClick={() => handleDownload("academico", recurso, api, keycloak.idTokenParsed.preferred_username)} variant="orange" style={{ margin: "30px 0 20px 0" }} startIcon={<LanguageIcon />}>
                            Acceder al recurso
                        </Button>

                )}
                {(!keycloak.authenticated && recurso["visibility"] === "public") && (
                    recurso.detail.reference === "direct" ?
                        <Button sx={{"fontSize": {"xs": "0.7em", "sm": "0.9em"}}} onClick={() => handleDownload("academico", recurso, api, "")} variant="orange" style={{ margin: "30px 0 20px 0" }} startIcon={<DownloadIcon />}>
                            Descargar recurso
                        </Button>
                        :
                        <Button sx={{"fontSize": {"xs": "0.7em", "sm": "0.9em"}}} onClick={() => handleDownload("academico", recurso, api, "")} variant="orange" style={{ margin: "30px 0 20px 0" }} startIcon={<LanguageIcon />}>
                            Acceder al recurso
                        </Button>

                )}
                {(!keycloak.authenticated && recurso["visibility"] === "semipublic") && (
                    <Tooltip title={
                        <div>
                            <Typography color="white">Por favor inicie sesión</Typography>
                        </div>
                    }>
                        {recurso.detail.reference === "direct" ?
                            <Button sx={{"fontSize": {"xs": "0.7em", "sm": "0.9em"}}} variant="orange" style={{ margin: "30px 0 20px 0" }} startIcon={<DownloadIcon />}>
                                Descargar recurso
                            </Button>
                            :
                            <Button sx={{"fontSize": {"xs": "0.7em", "sm": "0.9em"}}} variant="orange" style={{ margin: "30px 0 20px 0" }} startIcon={<LanguageIcon />}>
                                Acceder al recurso
                            </Button>
                        }
                    </Tooltip>
                )}
                <Box mt={"20px"} display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
                    <Typography fontSize={{"xs": "0.75em", "sm": "0.85em"}} textAlign={"justify"}>{"Material compartido por " + recurso.first_name + " " + recurso.last_name + " para RedFID."}</Typography>
                </Box>
            </Box>
        </Grid >
    );
}

export default RecursoContent;