import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';


const MaterialDocenteAuthors = ({  authors, setAuthors, errors, setErrors, formLoading, formSubmitted }) => {


    const handleAddAuthor = () => {
        const newAuthors = [
            ...authors,
            {
                author: "",
                institution: ""
            }
        ];
        const newErrors = {
            ...errors,
            authors: [
                ...errors.authors,
                {
                    author: "",
                    institution: ""
                }
            ]
        };
        setAuthors(newAuthors);
        setErrors(newErrors);
    }

    const handleRemoveAuthor = (index) => {
        const newAuthors = authors.filter((author, idx) => idx !== index);
        const newErrors = {
            ...errors,
            authors: errors.authors.filter((file, idx) => idx !== index)
        };
        setAuthors(newAuthors);
        setErrors(newErrors);
    }

    return (
        <>
            {authors.map((author, index) => (
                
                <Grid container key={index}>
                    <Grid item xs={12} sm={5.5} mt={"20px"}>
                        <TextField
                            fullWidth
                            label="Nombre"
                            name={"author-" + index}
                            value={authors[index].author}
                            onChange={(e) => {
                                const newAuthors = authors.map((author, idx) => {
                                    if (idx === index) {
                                        return { ...author, author: e.target.value };
                                    }
                                    return author;
                                });
                                setAuthors(newAuthors);
                                const newErrors = {
                                    ...errors,
                                    authors: [
                                        ...errors.authors
                                    ]
                                };
                                newErrors.authors[index] = {
                                    ...errors.authors[index],
                                    author: ""
                                };
                                setErrors(newErrors);
                            }}
                            disabled={formLoading || formSubmitted}
                        />
                        {errors.authors[index]["author"] && <span style={{ color: '#d32f2f', fontSize: '0.75rem', textAlign: "center" }}>{errors.authors[index]["author"]}</span>}

                    </Grid>
                    <Grid item xs={12} sm={5.5} mt={"20px"} pl={{"xs": "0", "sm": "20px"}}>
                        <TextField
                            fullWidth
                            label="Afiliación"
                            name={"institucion-" + index}
                            value={authors[index].institution}
                            onChange={(e) => {
                                const newAuthors = authors.map((author, idx) => {
                                    if (idx === index) {
                                        return { ...author, institution: e.target.value };
                                    }
                                    return author;
                                });
                                setAuthors(newAuthors);
                                const newErrors = {
                                    ...errors,
                                    authors: [
                                        ...errors.authors
                                    ]
                                };
                                newErrors.authors[index] = {
                                    ...errors.authors[index],
                                    institution: ""
                                };
                                setErrors(newErrors);
                            }}
                            disabled={formLoading || formSubmitted}
                        />
                        {errors.authors[index]["institution"] && <span style={{ color: '#d32f2f', fontSize: '0.75rem', textAlign: "center" }}>{errors.authors[index]["institution"]}</span>}
                    </Grid>
                    <Grid item xs={6} sm={0.5} mt={"20px"} textAlign={"center"}>
                        {authors.length > 1 && (
                            <IconButton onClick={() => handleRemoveAuthor(index)}>
                                <RemoveCircleOutlineIcon />
                            </IconButton>
                        )}
                    </Grid>
                    <Grid item xs={6} sm={0.5} mt={"20px"} textAlign={"center"}>
                        {(authors.length < 10 && (index+1) === authors.length) && (
                            <IconButton onClick={() => handleAddAuthor()}>
                                <AddCircleOutlineIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            ))}
        </>
    );

};

export default MaterialDocenteAuthors;