import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import DownloadIcon from '@mui/icons-material/Download';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import urlIcon from '../../assets/img/url.png';
import mp4Icon from '../../assets/img/mp4.png';
import pngIcon from '../../assets/img/png.png';
import pdfIcon from '../../assets/svg/pdf.svg';
import fileIcon from '../../assets/img/fileIcon.png';
import docxIcon from '../../assets/svg/docx.svg';
import pptxIcon from '../../assets/svg/pptx.svg';
import xlsxIcon from '../../assets/svg/xlsx.svg';
import ytIcon from '../../assets/svg/yt.svg';
import { handleDownload } from '../../utils/download';
import { getBigChip, getLevelChip, getStageChip, getRecursoDocenteChip } from '../../utils/chips';
import formatMaterialDocenteAuthors from '../../utils/formatMaterialDocenteAuthors';
import { useNavigate } from 'react-router-dom';
import api from '../../API';

const ActivityContent = ({ keycloak, activity }) => {

    const navigate = useNavigate();

    return (
        <Grid
            component="div"
            sx={{
            flexGrow: 1,
            maxWidth: '940px',
            width: '100%',
            padding: "30px 20px",
            }}
        >
            <Typography onClick={() => {keycloak.didInitialize && navigate("/")}} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
                <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
                <Typography variant="back">Volver</Typography>
            </Typography>
            <Box textAlign={"right"}>
                {getStageChip(activity["stage"])}
                {getLevelChip(activity["level"])}
                {getBigChip("material_docente")}
            </Box>
            <Typography sx={{"fontSize": {"xs": "1em", "sm": "1.4em"}}} variant='title' textAlign={"justify"}>{activity["title"]}</Typography>
            <Typography sx={{"fontSize": {"xs": "0.85em", "sm": "1.1em"}}} variant='main-subtitle'>{formatMaterialDocenteAuthors(activity["authors"])}</Typography>
            {activity["description"] !== "" &&
                <>
                    <Typography fontSize={{"xs": "0.75em", "sm": "0.85em"}} textAlign={"justify"}>{activity["description"]}</Typography>
                </>
            }
            <Card sx={{ "boxShadow": "none" }}>
                <CardContent>
                    <Typography variant="subtitle" fontSize={"1em"}>Recursos</Typography>
                    {activity.recursos.map((recurso, index) => {
                        return (
                            recurso.reference === "subir_archivo" ?
                                <Grid container key={recurso.id} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Grid item xs={12} sm={8} display={"flex"} alignItems={"center"}>
                                        {recurso.filename.endsWith(".pdf") ? (
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 50,
                                                    width: 65,
                                                }}
                                                alt="PDF"
                                                src={pdfIcon}
                                            />
                                        ) : (recurso.filename.endsWith(".docx") || recurso.filename.endsWith(".doc") || recurso.filename.endsWith(".odt")) ? (
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 50,
                                                    width: 65,
                                                }}
                                                alt="DOCX"
                                                src={docxIcon}
                                            />
                                        ) : (recurso.filename.endsWith(".pptx") || recurso.filename.endsWith(".ppt") || recurso.filename.endsWith(".odp")) ? (
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 50,
                                                    width: 65,
                                                }}
                                                alt="PPTX"
                                                src={pptxIcon}
                                            />
                                        ) : (recurso.filename.endsWith(".xlsx") || recurso.filename.endsWith(".xls") || recurso.filename.endsWith(".ods")) ? (
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 50,
                                                    width: 65,
                                                }}
                                                alt="XLSX"
                                                src={xlsxIcon}
                                            />
                                        ) : (recurso.filename.endsWith(".png") || recurso.filename.endsWith(".jpg") || recurso.filename.endsWith(".jpeg") || recurso.filename.endsWith(".odi") || recurso.filename.endsWith(".gif")) ? (
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 50,
                                                    marginLeft: "8px",
                                                    marginRight: "8px"
                                                }}
                                                alt="PNG"
                                                src={pngIcon}
                                            />
                                        ) : (recurso.filename.endsWith(".mp4")) ? (
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 50,
                                                    width: 65,
                                                }}
                                                alt="MP4"
                                                src={mp4Icon}
                                            />
                                        ) : (
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 50,
                                                    width: 50,
                                                    padding: "0px 9px"
                                                }}
                                                alt="Other"
                                                src={fileIcon}
                                            />
                                        )}
                                        <Box m={"0px 4px"} fontSize={"1em"}>
                                            {getRecursoDocenteChip(recurso.type)}
                                            <Typography sx={{
                                                maxWidth: {
                                                    "xs": "100px",
                                                    "sm": "none"
                                                }
                                            }} variant="small-p">{recurso.filename}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4} textAlign={"center"} alignSelf="flex-end">
                                        {keycloak.authenticated && (
                                            <Button onClick={() => handleDownload("docente", recurso, api, keycloak.idTokenParsed.preferred_username)} variant="orange" style={{ margin: "30px 0 20px 0", fontSize: "0.7em" }} startIcon={<DownloadIcon />}>
                                                Descargar
                                            </Button>
                                        )}
                                        {(!keycloak.authenticated && activity["visibility"] === "public") && (
                                            <Button onClick={() => handleDownload("docente", recurso, api, "")} variant="orange" style={{ margin: "30px 0 20px 0", fontSize: "0.7em" }} startIcon={<DownloadIcon />}>
                                                Descargar
                                            </Button>
                                        )}
                                        {(!keycloak.authenticated && activity["visibility"] === "semipublic") && (
                                            <Tooltip title={
                                                <div>
                                                    <Typography color="white">Por favor inicie sesión</Typography>
                                                </div>
                                            }>
                                                <Button variant="orange" style={{ margin: "30px 0 20px 0", fontSize: "0.7em" }} startIcon={<DownloadIcon />}>
                                                    Descargar
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                </Grid> :
                                <Grid key={recurso.id} display={"flex"} justifyContent={"space-between"} alignItems={"center"} minHeight={"72px"}>
                                    <Grid display={"flex"}>
                                        <Box
                                            component="img"
                                            sx={{
                                                marginBottom: "5px",
                                                marginLeft: "8px",
                                                marginRight: "10px",
                                                height: 50,
                                                width: 50,
                                            }}
                                            alt="URL"
                                            src={recurso.url.includes("youtube") ? ytIcon : urlIcon}
                                        />
                                        <Box m={"0px 4px"} fontSize={"1em"}>
                                            {getRecursoDocenteChip(recurso.type)}
                                            <Typography onClick={() => handleDownload("docente", recurso, api, (keycloak.didInitialize && keycloak.authenticated ? keycloak.idTokenParsed.preferred_username : ""))} variant="a">{recurso.url}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                        );
                    })}
                </CardContent>
            </Card>
            <Box mt={"20px"} display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
                <Typography fontSize={{"xs": "0.75em", "sm": "0.85em"}} textAlign={"justify"}>{activity.utility}</Typography>
            </Box>
            <Box mt={"20px"} display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
                <Typography fontSize={{"xs": "0.75em", "sm": "0.85em"}} textAlign={"justify"}>{"Material compartido por " + activity.first_name +  " " + activity.last_name + " para RedFID."}</Typography>
            </Box>
            <Box textAlign={"center"} alignSelf="flex-end">
            </Box>
        </Grid >
    );
}

export default ActivityContent;