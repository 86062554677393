import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NotFound from '../../Errors/NotFound';
import RecursoContent from './RecursoContent';
import api from '../../API';


const Recurso = ({ keycloak }) => {

  const [recurso, setRecurso] = useState(null);
  const [loadingRecurso, setLoadingRecurso] = useState(true);
  const location = useLocation();
  const pathDetails = location.pathname.split('/').filter(Boolean);

  const loadRecurso = () => {
    if (pathDetails.length !== 2) {
      return <NotFound></NotFound>
    }
    axios.get(api.api_url + "/recursos/" + pathDetails[1] + "?username=" + (keycloak.authenticated ? keycloak.idTokenParsed.preferred_username : ""))
      .then(response => {
        setLoadingRecurso(false);
        setRecurso(response.data);
        document.title = response.data.detail.title + " | RedFID Recursos";
      })
      .catch(error => {
        if(error.response.status === 403) {
          setRecurso(null);
          setLoadingRecurso(false);
        } else if (error.response.status === 404) {
          setRecurso(null);
          setLoadingRecurso(false);
        } else {
          console.log(error)
        }
      });
  }

  useEffect(() => {
    if (keycloak.didInitialize){
      loadRecurso();
    }
  }, [keycloak.authenticated])

  return (

    loadingRecurso ?
      <></>
      : (recurso && (recurso.visibility !== "private" || (keycloak.authenticated && recurso.username === keycloak.idTokenParsed.preferred_username))) ? 
      <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: 'center' }}>
        <Header keycloak={keycloak} />
        <RecursoContent keycloak={keycloak} recurso={recurso}></RecursoContent> 
        <Footer />
      </Grid>
      :
        <NotFound keycloak={keycloak}></NotFound>


  );
}

export default Recurso;