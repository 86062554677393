import { createTheme } from '@mui/material/styles'

let theme = createTheme({
    typography: {
        "main-title": {
            fontSize: '2em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#646464',
            fontFamily: 'Avenir Heavy',
            marginBottom: '20px',
            display: 'block',
            width: '100%',
            textAlign: 'left',
            fontWeight: 'bold'
        },
        title: {
            fontSize: '1.6em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#646464',
            fontFamily: 'Avenir Heavy',
            display: 'block',
            width: '100%',
            textAlign: 'left',
            fontWeight: 'bold'
        },
        "title-small": {
            fontSize: '1.2em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#646464',
            fontFamily: 'Avenir Heavy',
            marginBottom: '20px',
            display: 'block',
            width: '100%',
            textAlign: 'left',
            fontWeight: 'bold'
        },
        "main-subtitle": {
            fontSize: '1.2em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#646464',
            fontFamily: 'Avenir Regular',
            marginBottom: '20px',
            display: 'block',
            width: '100%',
            textAlign: 'left',
            fontWeight: 'normal'
        },
        subtitle: {
            fontSize: '1.3em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#646464',
            fontFamily: 'Avenir Heavy',
            marginBottom: '20px',
            display: 'block',
            width: '100%',
            textAlign: 'left'
        },
        "go-back": {
            fontSize: '0.8em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#40b4ba',
            fontFamily: 'Avenir Regular',
            cursor: 'pointer',
            display: 'block',
            textAlign: 'left'
        },
        a: {
            fontSize: '1em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: 'Avenir Regular',
            color: '#40b4ba',
            cursor: 'pointer',
            display: 'block',
        },
        "small-a": {
            fontSize: '0.85em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: 'Avenir Regular',
            color: '#40b4ba',
            cursor: 'pointer',
            display: 'block'
        },
        back: {
            fontSize: '0.8em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: 'Avenir Regular',
            color: '#40b4ba',
            cursor: 'pointer',
            display: 'block',
        },
        p: {
            fontSize: '0.85em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: 'Avenir Regular',
            color: '#646464',
            display: 'block',
        },
        "small-p": {
            fontSize: '0.7em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: 'Avenir Regular',
            color: '#646464',
            display: 'block'
        },
        "small-text": {
            fontSize: '0.85em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: 'Avenir Regular',
            color: '#646464',
            display: 'block',
        },
        error: {
            fontSize: '1em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: 'Avenir Regular',
            color: '#d32f2f',
            display: 'block',
        },
    },
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: '0.85em',
                    fontFamily: 'Avenir Regular',
                    color: '#646464',
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#40b4ba',
                    "&.Mui-checked": {
                        color: '#40b4ba',
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontSize: '0.9em',
                    fontFamily: 'Avenir Regular',
                    color: 'white',
                    height: "auto",
                    width: "auto",
                }
            },
            variants: [
                {
                    props: { variant: 'green' },
                    style: {
                        color: 'white',
                        backgroundColor: '#40b4ba',
                        fontFamily: 'Avenir Regular',
                    },
                },
                {
                    props: { variant: 'orange' },
                    style: {
                        color: 'white',
                        backgroundColor: '#eb947e',
                        fontFamily: 'Avenir Regular',
                    },
                }
            ]
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    color: '#40b4ba',
                },
                thumb: {
                    color: '#40b4ba',
                },
                track: {
                    color: '#40b4ba',
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '0.85em',
                    fontFamily: 'Avenir Regular',
                    color: '#646464',
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: '0.85em',
                    fontFamily: 'Avenir Regular',
                    color: '#646464',
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.85em',
                    fontFamily: 'Avenir Regular',
                    color: '#646464',
                }
            }
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'green' },
                    style: {
                        color: 'white',
                        backgroundColor: '#40b4ba',
                        '&:hover': {
                            backgroundColor: '#40b4ba',
                        },
                        '&disabled': {
                            color: 'white',
                        },
                        fontFamily: 'Avenir Regular',
                    },
                },
                {
                    props: { variant: 'orange' },
                    style: {
                        color: 'white',
                        backgroundColor: '#eb947e',
                        '&:hover': {
                            backgroundColor: '#eb947e',
                        },
                        '&:disabled': {
                            color: 'white',
                        },
                        fontFamily: 'Avenir Regular',
                    },
                },
                {
                    props: { variant: 'cancel' },
                    style: {
                        color: '#646464',
                        fontFamily: 'Avenir Regular',
                    },
                }
            ]
        },
        MuiIcon: {
            variants: [
                {
                    props: { variant: 'back' },
                    style: {
                        color: '#40b4ba',
                    },
                }
            ]
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    color: '#646464',
                    borderRadius: '10px',
                    padding: '10px',
                    marginBottom: '20px',
                },
                indicator: {
                    backgroundColor: '#40b4ba',
                    marginTop: "100px",
                    height: "5px"
                },
            },
        },
        MuiSelected: {
            styleOverrides: {
                root: {
                    color: '#40b4ba',
                },
            },
        },
        "Mui-selected": {
            styleOverrides: {
                color: '#40b4ba',
            },
        },


    },
});

export default theme;