import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Link, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import { formatDOI, unformatDOI } from '../../utils/formatters';
import { handleDownload } from '../../utils/download';


const LineaGraficaYDifusionSummary = ({ user, api, item, index }) => {

    return (
        <Accordion key={index}>
            <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls={`panel-${index}-content`}
                id={`panel-${index}-header`}
            >
                <Box mr={"20px"}>
                    <Typography textAlign={"justify"} fontWeight={"bold"}>{item.detail.title}</Typography>
                    {item.detail.subtitle &&
                        <Typography fontWeight={"bold"} variant="small-text">{item.detail.subtitle}</Typography>
                    }
                    <Typography variant="small-text">{item.detail.author} ({item.detail.year})</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
                        <Box>
                            {(item.detail.reference === "doi") &&
                                <Box display={"flex"} justifyContent={"left"}>
                                    <Typography variant="small-text" fontWeight={"bold"} mr={"10px"}>DOI: </Typography>
                                    <Link href={formatDOI(item.detail.doi)} target="_blank"><Typography variant='small-a'>{unformatDOI(formatDOI(item.detail.doi))}</Typography></Link>
                                </Box>
                            }
                            {(item.detail.reference === "url") &&
                                <Box display={"flex"} justifyContent={"left"}>
                                    <Typography variant="small-text" fontWeight={"bold"} mr={"10px"}>URL: </Typography>
                                    <Link href={item.detail.url} target="_blank"><Typography variant='small-a'>{item.detail.url}</Typography></Link>
                                </Box>
                            }
                            {item.detail.editor &&
                                <Box display={"flex"} justifyContent={"left"}>
                                    <Typography variant="small-text" fontWeight={"bold"} mr={"10px"}>Editor: </Typography>
                                    <Typography variant="small-text">{item.detail.editor}</Typography>
                                </Box>
                            }
                            {item.detail.journal &&
                                <Box display={"flex"} justifyContent={"left"}>
                                    <Typography variant="small-text" fontWeight={"bold"} mr={"10px"}>Revista: </Typography>
                                    <Typography variant="small-text">{item.detail.journal}</Typography>
                                </Box>
                            }
                            {item.detail.volume &&
                                <Box display={"flex"} justifyContent={"left"}>
                                    <Typography variant="small-text" fontWeight={"bold"} mr={"10px"}>Volumen: </Typography>
                                    <Typography variant="small-text">{item.detail.volume}</Typography>
                                </Box>
                            }
                            {item.detail.issue &&

                                <Box display={"flex"} justifyContent={"left"}>
                                    <Typography variant="small-text" fontWeight={"bold"} mr={"10px"}>Número: </Typography>
                                    <Typography variant="small-text">{item.detail.issue}</Typography>
                                </Box>
                            }
                            {item.detail.pages &&
                                <Box display={"flex"} justifyContent={"left"}>
                                    <Typography variant="small-text" fontWeight={"bold"} mr={"10px"}>Páginas: </Typography>
                                    <Typography variant="small-text">{item.detail.pages}</Typography>
                                </Box>
                            }
                        </Box>
                        {(item.detail.abstract && item.subcategory !== "poster") &&
                            <Box width={"50%"} m={"0px 20px"}>
                                <Typography variant="small-text" textAlign={"justify"}>{item.detail.abstract}</Typography>
                            </Box>
                        }
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mt={"20px"}>
                        {user ?
                            (
                                <>
                                    <IconButton onClick={() => handleDownload("difusion", item, api)}>
                                        <DownloadIcon />
                                    </IconButton>
                                    <IconButton>
                                        <ShareIcon />
                                    </IconButton>
                                </>
                            ) : <></>
                        }
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>

    )
}

export default LineaGraficaYDifusionSummary;