import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Link, Grid, IconButton, Avatar, CircularProgress, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import api from '../../API';

const HeaderUser = ({ keycloak, mobile, userButton, handleDisplayMobileMenu }) => {

    const [loadingImage, setLoadingImage] = useState(true);
    const [image, setImage] = useState(null);

    const getUserImage = () => {
        axios.get(api.api_url + "/get_user_image_url/" + keycloak.idTokenParsed.preferred_username)
            .then(response => {
                setLoadingImage(false);
                setImage(process.env.REACT_APP_DISCOURSE_URL + response.data);
            })
            .catch(error => {
                if (error.response.status === 403) {
                    setImage(null);
                    setLoadingImage(false);
                } else if (error.response.status === 404) {
                    setImage(null);
                    setLoadingImage(false);
                } else {
                    console.log(error)
                }
            });
    }

    useEffect(() => {
        if (keycloak.didInitialize && keycloak.authenticated){
            getUserImage();
        }
    }, [keycloak.authenticated]);

    return (
        <>
            {(keycloak.didInitialize && keycloak.authenticated) ? 
                <Grid sx={{"marginLeft": "auto"}} display="flex" alignItems="center" justifyContent={"end"}>
                    <IconButton ref={userButton} onClick={() => handleDisplayMobileMenu("user")}>
                        <Avatar sx={{ width: 40, height: 40 }} color={"#40b4ba"} src={loadingImage ? null : image} />
                    </IconButton>
                </Grid>
            : 
            keycloak.didInitialize && !keycloak.authenticated ?
            <Grid sx={{"marginLeft": "auto"}} display="flex" alignItems="center" justifyContent={"end"}>
                {!mobile && <Link target="_blank" rel="noopener" href={process.env.REACT_APP_HOME_URL + "/formulario-de-registro"}><Typography variant="a" mr={"15px"} fontWeight={"bold"} fontSize={"0.95em"}>Regístrate</Typography></Link>}
                <IconButton ref={userButton} onClick={() => handleDisplayMobileMenu("user")} sx={{"backgroundColor": "#40b4ba", "&:hover": {"backgroundColor": "#40b4ba"}}}>
                    <PersonIcon sx={{"color": "white"}} />
                </IconButton>
            </Grid> 
            : <Box ml="auto" display="flex" alignItems="end" justifyContent="end"><CircularProgress sx={{ color: "#40b4ba" }} /></Box>}
        </>
    );
};

export default HeaderUser;