import React, { cloneElement } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import theme from './theme';
import oidcConfiguration from './Context/oidcConfig';
import { ThemeProvider } from '@mui/material/styles';
import NotFound from './Errors/NotFound';
import Home from './Content/Home/Home';
import FormularioMaterialAcademico from './Content/Formulario/FormularioMaterialAcademico';
import FormularioMaterialDocente from './Content/Formulario/FormularioMaterialDocente';
import FormularioLineaGraficaYDifusion from './Content/Formulario/FormularioLineaGraficaYDifusion';
import Recurso from './Content/Recurso/Recurso';
import Activity from './Content/Activity/Activity';
import MaterialDocente from './Content/MaterialDocente/MaterialDocente';
import MaterialAcademico from './Content/MaterialAcademico/MaterialAcademico';
import LineaGraficaYDifusion from './Content/LineaGraficaYDifusion/LineaGraficaYDifusion';
import Recursos from './Content/Recursos/Recursos';
import MisRecursos from './Content/MisRecursos/MisRecursos';
import { KeycloakProvider, useKeycloak } from "keycloak-react-web"
import Keycloak from 'keycloak-js';
import SilentSSOCheck from './Context/SilentSSORedirect';

const keycloakSetting = oidcConfiguration;

const authInstance = new Keycloak(keycloakSetting)

function App() {

  return (
    <KeycloakProvider client={authInstance} initOptions={{
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
    }}>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
            <Route path="/academico" element={<ProtectedRoute><MaterialAcademico /></ProtectedRoute>} />
            <Route path="/academico/formulario" element={<ProtectedRoute><FormularioMaterialAcademico /></ProtectedRoute>} />
            <Route path="/docente" element={<ProtectedRoute><MaterialDocente /></ProtectedRoute>} />
            <Route path="/docente/formulario" element={<ProtectedRoute><FormularioMaterialDocente /></ProtectedRoute>} />
            <Route path="/difusion" element={<ProtectedRoute><LineaGraficaYDifusion /></ProtectedRoute>} />
            <Route path="/difusion/formulario" element={<ProtectedRoute><FormularioLineaGraficaYDifusion /></ProtectedRoute>} />
            <Route path="/recursos" element={<ProtectedRoute><Recursos /></ProtectedRoute>} />
            <Route path="/me" element={<ProtectedRoute><MisRecursos /></ProtectedRoute>} />
            <Route path="/recurso/*" element={<ProtectedRoute><Recurso /></ProtectedRoute>} />
            <Route path="/activity/*" element={<ProtectedRoute><Activity /></ProtectedRoute>} />
            <Route path="/silent-check-sso.html" element={<SilentSSOCheck  />} />
            <Route path="*" element={<ProtectedRoute><NotFound /></ProtectedRoute>} />
          </Routes>
        </Router>
      </ThemeProvider>
    </KeycloakProvider>
  );
}


const ProtectedRoute = ({ children }) => {
  const { keycloak } = useKeycloak();

  keycloak.onTokenExpired = () => {
    keycloak.updateToken(50);
  };

  const childWithProps = React.Children.map(children, child => {
      return cloneElement(child, { keycloak: keycloak });
  });

  return <>{childWithProps}</>;
};

export default App;