import React, { useState } from 'react';
import axios from 'axios';
import { Accordion, AccordionDetails, AccordionSummary, AccordionActions, Box, Link, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import formatMaterialDocenteAuthors from '../../utils/formatMaterialDocenteAuthors';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import urlIcon from '../../assets/img/url.png';
import mp4Icon from '../../assets/img/mp4.png';
import pngIcon from '../../assets/img/png.png';
import fileIcon from '../../assets/img/fileIcon.png';
import pdfIcon from '../../assets/svg/pdf.svg';
import docxIcon from '../../assets/svg/docx.svg';
import pptxIcon from '../../assets/svg/pptx.svg';
import xlsxIcon from '../../assets/svg/xlsx.svg';
import ytIcon from '../../assets/svg/yt.svg';
import { handleDownload } from '../../utils/download';
import { getStageChip, getLevelChip, getRecursoDocenteChip, getPendingChip } from '../../utils/chips';
import { useNavigate } from 'react-router-dom';
import api from '../../API';
import { formatFileSize } from '../../utils/formatters';


const MaterialDocenteSummary = ({ keycloak, item, index, showTag, isMine }) => {

    const navigate = useNavigate();

    const [deleteModal, setDeleteModal] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const getIconset = () => {
        let out = [];
        let marked = [];

        for (let recurso of item.recursos) {
            if (marked.includes(recurso.type)) {
                continue;
            }

            let name = getRecursoDocenteChip(recurso.type).props.label;
            marked.push(recurso.type);

            out.push(
                <div key={recurso.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', marginRight: "10px", fontSize: "0.9em" }}>
                    <CheckCircleOutlineIcon style={{ color: "#646464", fontSize: "1em" }} />
                    <Typography ml={"5px"} variant="p">{name}</Typography>
                </div>
            );
        }

        return out;
    };

    const handleDelete = () => {
        setDeleting(true);
        axios.post(api.api_url + "/delete_activity/" + item.id, {}, { headers: { 'Authorization': 'Bearer ' + keycloak.token } })
            .then(response => {
                if (response.status === 200) {
                    console.log("Actividad eliminada");
                }
                setDeleting(false);
                handleCloseDeleteModal();
                window.location.reload();   
            })
            .catch(error => {
                setDeleting(false);
                console.log(error)
            });
    }

    const handleOpenDeleteModal = () => {
        setDeleteModal(true);
    }

    const handleCloseDeleteModal = () => {
        setDeleteModal(false);
    }

    return (
        <>
            <Accordion key={index} sx={{ "boxShadow": "none", "backgroundColor": index % 2 === 0 ? "#e9eeef" : "#f3f3f3", "marginBottom": "10px" }}>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls={`panel-${index}-content`}
                    id={`panel-${index}-header`}
                >
                    <Grid mr={"20px"} width={"100%"}>
                        <Box display={"flex"} flexDirection={{"xs": "column", "sm": "row"}}>
                            {isMine && 
                                <Box>
                                    {getPendingChip(item.status)}
                                </Box>
                            }
                            <Box ml={isMine ? "auto" : "none"}>
                                {showTag && <Chip sx={{ "margin": "0px 5px 10px 5px", "fontSize": {"xs": "0.7em", "sm": "0.9em"}, "backgroundColor": "#82bade" }} p={0} label={"Material docente"} />}
                                {getStageChip(item.stage)}
                                {getLevelChip(item.level)}
                            </Box>
                        </Box>
                        <Typography fontSize={{"xs": "0.9em", "sm": "1em"}} ml={"12px"} textAlign={"justify"} fontWeight={"bold"}>{item.title}</Typography>
                        <Typography fontSize={{"xs": "0.8em", "sm": "0.85em"}} ml={"12px"} variant="small-text">{formatMaterialDocenteAuthors(item.authors)}</Typography>
                        <Box ml={"12px"} mt={"10px"} color={"#646464"} display={"flex"} alignItems={"center"} flexWrap="wrap">
                            {getIconset()}
                        </Box>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid ml={"12px"} mr={"20px"}>
                        <Box mb={"20px"} display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
                            <Typography fontSize={{"xs": "0.8em", "sm": "0.85em"}} variant="p" textAlign={"justify"}>{item.description}</Typography>
                        </Box>
                        <Card sx={{ "boxShadow": "none" }}>
                            <CardContent>
                                <Typography variant="subtitle" fontSize={"1em"}>Recursos</Typography>
                                {item.recursos.map((recurso, index) => {
                                    return (
                                        recurso.reference === "subir_archivo" ?
                                            <Grid container key={recurso.id} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                <Grid item xs={12} sm={8} display={"flex"} alignItems={"center"}>
                                                    {recurso.filename.endsWith(".pdf") ? (
                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                height: 50,
                                                                width: 65,
                                                            }}
                                                            alt="PDF"
                                                            src={pdfIcon}
                                                        />
                                                    ) : (recurso.filename.endsWith(".docx") || recurso.filename.endsWith(".doc") || recurso.filename.endsWith(".odt")) ? (
                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                height: 50,
                                                                width: 65,
                                                            }}
                                                            alt="DOCX"
                                                            src={docxIcon}
                                                        />
                                                    ) : (recurso.filename.endsWith(".pptx") || recurso.filename.endsWith(".ppt") || recurso.filename.endsWith(".odp")) ? (
                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                height: 50,
                                                                width: 65,
                                                            }}
                                                            alt="PPTX"
                                                            src={pptxIcon}
                                                        />
                                                    ) : (recurso.filename.endsWith(".xlsx") || recurso.filename.endsWith(".xls") || recurso.filename.endsWith(".ods")) ? (
                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                height: 50,
                                                                width: 65,
                                                            }}
                                                            alt="XLSX"
                                                            src={xlsxIcon}
                                                        />
                                                    ) : (recurso.filename.endsWith(".png") || recurso.filename.endsWith(".jpg") || recurso.filename.endsWith(".jpeg") || recurso.filename.endsWith(".odi") || recurso.filename.endsWith(".gif")) ? (
                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                height: 50,
                                                                marginLeft: "8px",
                                                                marginRight: "8px"
                                                            }}
                                                            alt="PNG"
                                                            src={pngIcon}
                                                        />
                                                    ) : (recurso.filename.endsWith(".mp4")) ? (
                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                height: 50,
                                                                width: 65,
                                                            }}
                                                            alt="MP4"
                                                            src={mp4Icon}
                                                        />
                                                    ) : (
                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                height: 50,
                                                                width: 50,
                                                                padding: "0px 9px"
                                                            }}
                                                            alt="Other"
                                                            src={fileIcon}
                                                        />
                                                    )}
                                                    <Grid m={"0px 4px"} fontSize={"1em"}>
                                                        {getRecursoDocenteChip(recurso.type)}
                                                        <Typography sx={{
                                                            maxWidth: {
                                                                "xs": "100px",
                                                                "sm": "none"
                                                            }
                                                        }} variant="small-p">{recurso.filename + " (" + formatFileSize(recurso.filesize) + ")"}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={4} textAlign={"center"} alignSelf="flex-end">
                                                    {keycloak.authenticated && (
                                                        <Button onClick={() => handleDownload("docente", recurso, api, keycloak.idTokenParsed.preferred_username)} variant="orange" style={{ margin: "30px 0 20px 0", fontSize: "0.7em" }} startIcon={<DownloadIcon />}>
                                                            Descargar
                                                        </Button>
                                                    )}
                                                    {(!keycloak.authenticated && item["visibility"] === "public") && (
                                                        <Button onClick={() => handleDownload("docente", recurso, api, "")} variant="orange" style={{ margin: "30px 0 20px 0", paddingLeft: "20px", fontSize: "0.7em" }} startIcon={<DownloadIcon />}>
                                                            Descargar
                                                        </Button>
                                                    )}
                                                    {(!keycloak.authenticated && item["visibility"] === "semipublic") && (
                                                        <Tooltip title={
                                                            <div>
                                                                <Typography color="white">Por favor inicie sesión</Typography>
                                                            </div>
                                                        }>
                                                            <Button variant="orange" style={{ margin: "30px 0 20px 0", fontSize: "0.7em" }} startIcon={<DownloadIcon />}>
                                                                Descargar
                                                            </Button>
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                            </Grid> :
                                            <Grid key={recurso.id} display={"flex"} justifyContent={"space-between"} alignItems={"center"} minHeight={"72px"}>
                                                <Grid display={"flex"}>
                                                    <Box
                                                        component="img"
                                                        sx={{
                                                            marginBottom: "5px",
                                                            marginLeft: "8px",
                                                            marginRight: "10px",
                                                            height: 50,
                                                            width: 50,
                                                        }}
                                                        alt="URL"
                                                        src={recurso.url.includes("youtube") ? ytIcon : urlIcon}
                                                    />
                                                    <Box m={"0px 4px"} fontSize={"1em"}>
                                                        {getRecursoDocenteChip(recurso.type)}
                                                        <Typography fontSize={{"xs": "0.85em", "sm": "1em"}} onClick={() => handleDownload("docente", recurso, api, (keycloak.didInitialize && keycloak.authenticated ? keycloak.idTokenParsed.preferred_username : ""))} variant="a">{recurso.url}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                    );
                                })}
                            </CardContent>
                        </Card>
                        <Box mt={"20px"} display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
                            <Typography fontSize={{"xs": "0.8em", "sm": "0.85em"}} variant="p" textAlign={"justify"}>{item.utility}</Typography>
                        </Box>
                    </Grid>
                </AccordionDetails>
                <AccordionActions sx={{ "paddingBottom": "20px" }}>
                    <Typography fontSize={{"xs": "0.8em", "sm": "0.85em"}} variant="p" mr={"auto"} ml="20px">Material compartido por {item.first_name + " " + item.last_name} para RedFID.</Typography>
                    {item.status === "approved" &&
                        <IconButton onClick={() => { keycloak.didInitialize && navigate("/activity/" + item.id) }}>
                            <ShareIcon />
                        </IconButton>
                    }
                    {(keycloak.authenticated && isMine) && 
                        <IconButton onClick={() => handleOpenDeleteModal()}>
                            <DeleteForeverIcon />
                        </IconButton>
                    }
                </AccordionActions>
            </Accordion>
            {isMine && 
                <Dialog
                    fullWidth
                    open={deleteModal}
                    onClose={handleCloseDeleteModal}
                >
                    <DialogTitle sx={{ "margin": "20px 20px 0 20px" }}>{"Eliminar " + (item.status === "proposed" ? "solicitud" : "recurso")}</DialogTitle>
                    <DialogContent sx={{ "margin": "20px 20px 0 20px" }}>
                        <DialogContentText sx={{ "fontFamily": "Avenir Regular", "fontSize": "0.9em" }}>
                            {"¿Está segur@ que desea eliminar " + (item.status === "proposed" ? "esta solicitud?" : "este recurso?")}
                        </DialogContentText>
                        <DialogContentText sx={{ "fontFamily": "Avenir Regular", "fontSize": "0.9em", "marginTop": "10px", "textAlign": "center", "fontWeight": "bold", "margin": "20px 0px" }}>
                            {item.title}
                        </DialogContentText>
                        <DialogContentText sx={{ "fontFamily": "Avenir Regular", "fontSize": "0.9em" }}>
                            {"Esta acción no se puede deshacer."}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ "margin": "0px 20px 20px 20px" }}>
                        <Button variant="cancel" onClick={handleCloseDeleteModal}>Cancelar</Button>
                        <Button variant="orange" onClick={handleDelete}>Eliminar</Button>
                    </DialogActions>
                </Dialog>
            }
        </>

    )
}

export default MaterialDocenteSummary;