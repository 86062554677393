import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Grid from '@mui/material/Grid';
import { CircularProgress, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import InputAdornment from '@mui/material/InputAdornment';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MaterialAcademicoSummary from '../components/MaterialAcademicoSummary';
import MaterialDocenteSummary from '../components/MaterialDocenteSummary';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import api from '../../API';

const Recursos = ({ keycloak }) => {

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState('');
  const [loadingRecursos, setLoadingRecursos] = useState(false);
  const [recursos, setRecursos] = useState([]);
  const [filters1, setFilters1] = useState({
    'investigacion': "1",
    'activities': "1",
    'difusion': "1",
  });
  const [filters2, setFilters2] = useState({
    'articles': "1",
    'congress_minutes': "1",
    'technical_reports': "1",
    'posters': "1",
    'books': "1",
    'book_chapters': "1",
    'websites': "1",
  });
  const [filters3, setFilters3] = useState({
    'educacion_basica': "1",
    'educacion_media': "1",
    'educacion_parvularia': "1"
  });
  const [filters4, setFilters4] = useState({
    'formacion_inicial': "1",
    'formacion_continua': "1"
  });
  const [filters5, setFilters5] = useState({
    'educacion_matematica': "1",
    'formacion_de_profesores': "1",
    'aprendizaje_profesional_de_formadores': "1",
    'material_redfid': "1",
    'matematica': "1",
    'didactica_de_la_matematica': "1",
    'formacion_pedagogica': "1",
    'formacion_practica': "1",
    'otros': "1",
  });

  const chipNames = {
    'investigacion': "Investigación",
    'activities': "Material docente",
    'difusion': "Línea gráfica y difusión",
    'articles': "Artículo",
    'congress_minutes': "Acta de congreso",
    'technical_reports': "Reporte técnico",
    'posters': "Póster",
    'books': "Libro",
    'book_chapters': "Capítulo de libro",
    'websites': "Página web",
    'educacion_basica': "Educación básica",
    'educacion_media': "Educación media",
    'educacion_parvularia': "Educación parvularia",
    'formacion_inicial': "Formación inicial",
    'formacion_continua': "Formación continua",
    'educacion_matematica': "Educación matemática",
    'formacion_de_profesores': "Formación de profesores",
    'aprendizaje_profesional_de_formadores': "Aprendizaje profesional del formador",
    'material_redfid': "Material cursos y talleres RedFID",
    'matematica': "Matemática",
    'didactica_de_la_matematica': "Didáctica de la matemática",
    'formacion_pedagogica': "Formación pedagógica",
    'formacion_practica': "Formación práctica",
    'otros': "Otros"
  }

  const chipColors = {
    'investigacion': "#40b4ba",
    'activities': "#eb947e",
    'difusion': "#595959",
    'articles': "#7285e4",
    'congress_minutes': "#bc7ee1",
    'technical_reports': "#e4d876",
    'posters': "#40b4ba",
    'books': "#e7706c",
    'book_chapters': "#009bb7",
    'websites': "#595959",
    'educacion_basica': "#e7706c",
    'educacion_media': "#e7706c",
    'educacion_parvularia': "#e7706c",
    'formacion_inicial': "#009bb7",
    'formacion_continua': "#009bb7",
    'educacion_matematica': "#40b4ba",
    'formacion_de_profesores': "#40b4ba",
    'aprendizaje_profesional_de_formadores': "#40b4ba",
    'material_redfid': "#40b4ba",
    'matematica': "#40b4ba",
    'didactica_de_la_matematica': "#40b4ba",
    'formacion_pedagogica': "#40b4ba",
    'formacion_practica': "#40b4ba",
    'otros': "#40b4ba"
  }

  const toggleFilter = (label) => {
    if (label in filters1) {
      setFilters1({ ...filters1, [label]: filters1[label] === "1" ? "0" : "1" });
    }
    if (label in filters2) {
      setFilters2({ ...filters2, [label]: filters2[label] === "1" ? "0" : "1" });
    }
    if (label in filters3) {
      setFilters3({ ...filters3, [label]: filters3[label] === "1" ? "0" : "1" });
    }
    if (label in filters4) {
      setFilters4({ ...filters4, [label]: filters4[label] === "1" ? "0" : "1" });
    }
    if (label in filters5) {
      setFilters5({ ...filters5, [label]: filters5[label] === "1" ? "0" : "1" });
    }
  }

  useEffect(() => {
    document.title = "Buscar recursos | RedFID Recursos";
  }, [])

  useEffect(() => {
    if (keycloak.didInitialize) {
      var params = {};
      if (searchParams.get('s') == null) {
        params['s'] = "";
      } else {
        params['s'] = searchParams.get('s');
        setSearch(searchParams.get('s'));
      }

      for (const [key, value] of Object.entries(filters1)) {
        if (searchParams.get(key) == null) {
          params[key] = value
        } else {
          params[key] = searchParams.get(key)
        }
      }
      for (const [key, value] of Object.entries(filters2)) {
        if (searchParams.get(key) == null) {
          params[key] = value
        } else {
          params[key] = searchParams.get(key)
        }
      }
      for (const [key, value] of Object.entries(filters3)) {
        if (searchParams.get(key) == null) {
          params[key] = value
        } else {
          params[key] = searchParams.get(key)
        }
      }
      for (const [key, value] of Object.entries(filters4)) {
        if (searchParams.get(key) == null) {
          params[key] = value
        } else {
          params[key] = searchParams.get(key)
        }
      }
      for (const [key, value] of Object.entries(filters5)) {
        if (searchParams.get(key) == null) {
          params[key] = value
        } else {
          params[key] = searchParams.get(key)
        }
      }
      setSearchParams(params);
      loadRecursos();
    }
  }, [keycloak.didInitialize, searchParams]);

  const loadRecursos = () => {
    setLoadingRecursos(true);
    var url = api.api_url + "/search_recursos";
    const queryParams = searchParams.toString();
    const urlWithParams = `${url}?${queryParams}`;
    axios.get(urlWithParams)
      .then(response => {
        setLoadingRecursos(false);
        setRecursos(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const handleSearch = () => {
    let newParams = {};
    newParams["s"] = search
    Object.keys(filters1).forEach(filter => {
      newParams[filter] = filters1[filter];
    });
    Object.keys(filters2).forEach(filter => {
      newParams[filter] = filters2[filter];
    });
    Object.keys(filters3).forEach(filter => {
      newParams[filter] = filters3[filter];
    });
    Object.keys(filters4).forEach(filter => {
      newParams[filter] = filters4[filter];
    });
    Object.keys(filters5).forEach(filter => {
      newParams[filter] = filters5[filter];
    });
    setSearchParams(newParams);
    loadRecursos();
  }

  return (
    <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: 'center' }}>
      <Header keycloak={keycloak} />
      <Grid
        component="div"
        sx={{
          flexGrow: 1,
          maxWidth: '940px',
          margin: "30px 20px",
        }}
      >
        <Grid mb={"20px"} container display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box>
            <Typography onClick={() => {keycloak.didInitialize && navigate("/")}} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
              <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
              <Typography variant="back">Volver</Typography>
            </Typography>
            <Typography variant='title' sx={{ fontSize: { sm: '1.6em', xs: '1.2em' } }}>Explorar recursos</Typography>
          </Box>
          {keycloak.authenticated &&
            <Box onClick={() => {keycloak.didInitialize && navigate("/me")}}>
              <Box sx={{ "backgroundColor": "#40b4ba", "display": "flex", "justifyContent": "space-between", "alignItems": "center", "padding": {"xs": "0px 10px 0px 0px", "sm": "5px 15px 5px 5px"}, "borderRadius": "5px", "&:hover": { "cursor": "pointer" } }} >
                <IconButton disableFocusRipple={true} disableRipple={true} sx={{ "color": "white" }}>
                  <DriveFolderUploadIcon />
                </IconButton>
                <Typography fontSize={{"xs": "0.8em", "sm": "0.9em"}} variant={"p"} color={"white"} fontWeight={"bold"}>Colaborar</Typography>
              </Box>
            </Box>
          }
        </Grid>
        <Typography fontSize={{"xs": "0.8em", "sm": "0.85em"}} variant='p' mb={"20px"}>Aquí podrás buscar artículos de investigación y material docente para la formación inicial docente en sintonía a tus intereses y motivaciones personales.</Typography>
        <Box sx={{ mb: '20px' }}>
          <TextField
            id="search"
            label="Buscar recursos"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
            onKeyDown={(e) => { if (e.key === 'Enter') { handleSearch() } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleSearch()}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <FilterAltIcon sx={{ marginRight: '5px', color: "#646464" }} />
              <Typography>Abrir filtros</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container rowSpacing={5} justifyContent={"space-between"}>
                <Grid item xs={12} sm={6} md={4} lg={2.5} display={"flex"} flexDirection={"column"}>
                  <Typography textAlign={"center"} variant={"p"} fontWeight={"bold"}>Clasificación</Typography>
                  {Object.keys(filters1).map(label => (
                    <Chip
                      sx={{ "marginBottom": "2px", "backgroundColor": filters1[label] === "1" ? chipColors[label] : "rgba(0, 0, 0, 0.12)" }}
                      key={label}
                      label={chipNames[label]}
                      clickable
                      onClick={() => toggleFilter(label)}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2} display={"flex"} flexDirection={"column"}>
                <Typography textAlign={"center"} variant={"p"} fontWeight={"bold"}>Investigación</Typography>
                  {Object.keys(filters2).map(label => (
                    <Chip
                      sx={{ "marginBottom": "2px", "backgroundColor": filters2[label] === "1" ? chipColors[label] : "rgba(0, 0, 0, 0.12)" }}
                      key={label}
                      label={chipNames[label]}
                      clickable
                      onClick={() => toggleFilter(label)}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2.3} display={"flex"} flexDirection={"column"}>
                <Typography textAlign={"center"} variant={"p"} fontWeight={"bold"}>Enfoque</Typography>
                  {Object.keys(filters3).map(label => (
                    <Chip
                      sx={{ "marginBottom": "2px", "backgroundColor": filters3[label] === "1" ? chipColors[label] : "rgba(0, 0, 0, 0.12)" }}
                      key={label}
                      label={chipNames[label]}
                      clickable
                      onClick={() => toggleFilter(label)}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2.2} display={"flex"} flexDirection={"column"}>
                <Typography textAlign={"center"} variant={"p"} fontWeight={"bold"}>Etapa formativa</Typography>
                  {Object.keys(filters4).map(label => (
                    <Chip
                      sx={{ "marginBottom": "2px", "backgroundColor": filters4[label] === "1" ? chipColors[label] : "rgba(0, 0, 0, 0.12)" }}
                      key={label}
                      label={chipNames[label]}
                      clickable
                      onClick={() => toggleFilter(label)}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} display={"flex"} flexDirection={"column"}>
                <Typography textAlign={"center"} variant={"p"} fontWeight={"bold"}>Categorías</Typography>
                  {Object.keys(filters5).map(label => (
                    <Chip
                      sx={{ "marginBottom": "2px", "backgroundColor": filters5[label] === "1" ? chipColors[label] : "rgba(0, 0, 0, 0.12)" }}
                      key={label}
                      label={chipNames[label]}
                      clickable
                      onClick={() => toggleFilter(label)}
                    />
                  ))}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box>
          {loadingRecursos ? <CircularProgress sx={{ "color": "#40b4ba" }} /> :
            <Box>
              {recursos.map((recurso, index) => (
                recurso.level == null ?
                  <MaterialAcademicoSummary keycloak={keycloak} key={index} item={recurso} isMine={false} />
                  : <MaterialDocenteSummary keycloak={keycloak} key={index} item={recurso} showTag={true} isMine={false} />
              ))}
            </Box>
          }
        </Box>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default Recursos;