import Chip from '@mui/material/Chip';


export const getBigChip = (type) => {
    var name = "";
    var color = "";
    switch (type) {
        case "material_academico":
            name = "Investigación";
            color = "#40b4ba"
            break;
        case "material_docente":
            name = "Material docente";
            color = "#eb947e"
            break;
        default:
            name = "Otro";
            color = "#646464"
            break;
    }
    return <Chip sx={{ "margin": "0px 5px 10px 5px", "backgroundColor": color,"fontSize": {"xs": "0.7em", "sm": "0.9em"} }} p={0} label={name} />
}


export const getPendingChip = (status) => {
    var name = "";
    var color = "";
    switch (status) {
        case "proposed":
            name = "En revisión...";
            color = "#e4d876"
            break;
        case "approved":
            name = "Publicado";
            color = "#40ad4e"
            break;
        case "rejected":
            name = "Rechazado";
            color = "#e7706c"
            break;
        default:
            name = "Ocultado por administración";
            color = "#646464"
            break;
    }
    return <Chip sx={{ "margin": "0px 5px 10px 5px", "backgroundColor": color,"fontSize": {"xs": "0.7em", "sm": "0.9em"} }} p={0} label={name} />
}


export const getCategoryChip = (category) => {
    var name = "";
    var color = "";
    switch (category) {
        case "article":
            name = "Artículo";
            color = "#7285e4"
            break;
        case "congress_minute":
            name = "Acta de congreso";
            color = "#bc7ee1"
            break;
        case "technical_report":
            name = "Reporte técnico";
            color = "#e4d876"
            break;
        case "poster":
            name = "Póster";
            color = "#40b4ba";
            break;
        case "book":
            name = "Libro";
            color = "#e7706c"
            break;
        case "book_chapter":
            name = "Capítulo de libro";
            color = "#009bb7"
            break;
        case "website":
            name = "Pagina web";
            color = "#595959"
            break;
        default:
            name = "Otro";
            color = "#646464"
            break;
    }
    return <Chip sx={{ "margin": "0px 5px 10px 5px", "backgroundColor": color,"fontSize": {"xs": "0.7em", "sm": "0.9em"}}} p={0} label={name} />
}

export const getStageChip = (stage) => {
    var name = "";
    var color = "#009bb7";
    switch (stage) {
        case "formacion_inicial":
            name = "Formación inicial";
            break;
        case "formacion_continua":
            name = "Formación continua";
            break;
        default:
            name = "Otro";
            break;
    }
    return <Chip sx={{ "margin": "0px 5px 10px 5px", "backgroundColor": color,"fontSize": {"xs": "0.7em", "sm": "0.9em"} }} p={0} label={name} />
}

export const getLevelChip = (level) => {
    var name = "";
    var color = "#e7706c";
    switch (level) {
        case "educacion_basica":
            name = "Educación básica";
            break;
        case "educacion_media":
            name = "Educación media";
            break;
        case "educacion_parvularia":
            name = "Educación parvularia";
            break;
        default:
            name = "Otro";
            break;
    }
    return <Chip sx={{ "margin": "0px 5px 10px 5px", "backgroundColor": color,"fontSize": {"xs": "0.7em", "sm": "0.9em"} }} p={0} label={name} />
}

export const getRecursoDocenteChip = (recurso) => {
    var name = "";
    switch (recurso) {
        case "programa_de_cursos":
            name = "Programa de un curso";
            break;
        case "apuntes_de_cursos":
            name = "Apuntes de un curso";
            break;
        case "planificacion":
            name = "Planificación";
            break;
        case "libro":
            name = "Texto de estudio";
            break;
        case "unidad_de_aprendizaje":
            name = "Unidad de aprendizaje";
            break;
        case "presentacion":
            name = "Presentación de clases";
            break;
        case "video_de_clases":
            name = "Video de clases";
            break;
        case "evaluacion":
            name = "Evaluación";
            break;
        case "rubrica":
            name = "Rúbrica";
            break;
        case "pauta_de_observacion":
            name = "Pauta de observación";
            break;
        case "encuesta":
            name = "Encuesta";
            break;
        case "recurso_multimodal":
            name = "Recurso multimodal";
            break;
        default:
            name = "Otro";
            break;
    }
    return <Chip p={0} label={name} sx={{"fontSize": {"xs": "0.7em", "sm": "0.9em"}, "backgroundColor": "#40b4ba"}} />
}